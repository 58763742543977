import { useState, useEffect } from "react";

const usePromotekitReferral = () => {
  const [referralCode, setReferralCode] = useState(null);

  useEffect(() => {

    // let promotest = 'sfweew';
    // if (promotest) {
    //   setReferralCode(promotest);
    // }

    // Check if the promotekit_referral is available
    if (window.promotekit_referral) {
      setReferralCode(window.promotekit_referral);
    }
    
  }, []);

  return referralCode;
};

export default usePromotekitReferral;