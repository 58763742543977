import React, { useEffect } from "react";
import "./Home.css";
import StarIcon from "@mui/icons-material/Star";
import Logo from "../assets/mainImage.svg";
import Hello from "../assets/hello.svg";
import Project from "../assets/project.svg";
import Money from "../assets/money.svg";
import Footer from "../components/Footer";
import LaptopIcon from "@mui/icons-material/Laptop";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import VerifiedIcon from "@mui/icons-material/Verified";
import Aos from "aos";
import "aos/dist/aos.css";

const Home = ({ darkMode }) => {
  useEffect(() => {
    Aos.init();
  }, []);

  const students =
    1000 >= 1300 ? 1000 : Math.floor(Math.random() * (1300 - 1000 + 1)) + 1000;
  console.log(students);
  console.log(darkMode);
  return (
    <div className="home light">
      <div className="home__main">
        <div className="home-left">
          <figure className="text__wrapper">
            <h1 className="home-title">
              Begin your frontend{" "}
              <span className="gold">software engineer</span> career today!
            </h1>
            <p className="home-subtitle">
              Build the skillset you need to become a capable, qualified
              software engineer with project-based, stuctured learning with a{" "}
              <span className="gold">job guarantee</span> after just{" "}
              <span className="gold">eight weeks!</span>
            </p>
            <a
              target="_blank"
              className="discord-home nav__link--btn top__nav--link"
              href="https://discord.gg/P8hxsbzsKS"
            >
              <img
                src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a6cc3c481a15a141738_icon_clyde_white_RGB.png"
                className="discord__logo"
                alt=""
              />
              <p className="disc">Discord</p>
            </a>
            <div className="btns__wrapper">
              <a href="/courseintro">
                <button className="get__started--btn" id="button">
                  Introduction
                </button>
              </a>
              <a href="/payment">
                <button className="btn2 get__started--btn">Sign Up</button>
              </a>
            </div>
            <figure className="star__wrapper">
              {/* <div className="stars">
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
                <StarIcon className="star__icon" />
              </div> */}

              {/* <figure className="active__wrapper">
                <img
                  src="https://icon-library.com/images/online-status-icon/online-status-icon-25.jpg"
                  className="verified__icon"
                  alt=""
                />
                <i className="active__students">{students} Currently Active Students</i>
              </figure> */}
            </figure>
          </figure>
        </div>
        <div className="home-right">
          <img
            className="home-img"
            src="https://i.pinimg.com/originals/9a/68/71/9a68716efc331fcc84e3a4ce5f23d18d.png"
            alt=""
          />
          {/* <div className="requirements__container">
          <div className="req-top">
          <figure className="req__figure">
              <TrendingUpIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Skill Level:</h5>
                <h5 className="requirement__value">Beginner</h5>
              </div>
            </figure>
            <figure className="req__figure">
              <AttachMoneyIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Average Salary:</h5>
                <h5 className="requirement__value">$119,061</h5>
              </div>
            </figure>
          </div>
            
          <div className="req-bottom">
          <figure className="req__figure">
              <SchoolIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Average Completion Time:</h5>
                <h5 className="requirement__value">8 Weeks</h5>
              </div>
            </figure>
            <figure className="req__figure">
              <WorkIcon className="req__icon" />
              <div className="figure__inner">
                <h5 className="requirement__title">Current Openings:</h5>
                <h5 className="requirement__value">218,350</h5>
              </div>
            </figure>
          </div>
            
          </div> */}
        </div>
      </div>

      <div className="main">
        <hr />
        {/* <div data-aos="zoom-in" className="testimonials">
          <h1 className="testimonials-header">
            Testimonials from Past Students
          </h1>
          <div className="test-wrapper">
            <div className="test-container">
              <h4 className="test-title">$75,000 Junior Role</h4>
              <p className="test-para">
                {" "}
                I contacted Zak after finding him on Tik Tok when he first
                started making videos and I asked him to give me a structured
                roadmap on how to learn. Not only did he give me a roadmap but
                he also tutored me as well as answered any questions or issues I
                had. I recently had a look at learnfrontend and it is the exact
                same roadmap with the same projects that I had on my portfolio
                that landed me a job only a few days after starting the
                application process!
              </p>
              <h4 className="test-name">- Adrian, 2023 Graduate.</h4>
            </div>
            <div className="test-container">
              <h4 className="test-title">$170,000 Netflix Role</h4>
              <p className="test-para">
                LearnFrontend is an unbelievably good, efficient resource. Zak
                teaches only what you need to know. There’s so much bs when it
                comes to code that you don’t need to know, so learning only what
                you absolutely NEED is crucial.
              </p>
              <h4 className="test-name">- Jack, 2023 Graduate.</h4>
            </div>
            <div className="test-container">
              <h4 className="test-title">$90,000 React Role</h4>
              <p className="test-para">
                Initially I was a bit skeptical finding LF on TikTok but I
                decided to give it a shot and it was the best decision of my
                life! Proud to be one of the first of many testimonials to come!
              </p>
              <h4 className="test-name">- Nathan, 2023 Graduate.</h4>
            </div>
          </div>
        </div> */}

        <h1 className="main__title" data-aos="zoom-in" data-aos-duration="1000">
          About LearnFrontend
        </h1>
        <div
          className="main__content"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="main__content--left">
            <h1 className="main__content--title">How Much Will I Earn?</h1>
            <p className="main__content--para">
              As of 2023, frontend developers in the United States typically
              earn an average salary of up to $120,000 per year, depending on
              factors like experience and location. In tech hubs, seasoned
              developers can command even higher salaries, often exceeding this
              average.
            </p>
          </div>
          <div className="main__content--right">
            <img className="main__content--img" src={Hello} alt="" />
          </div>
        </div>
        <hr className="hr3" />
        <div
          className="main__content"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <div className="main__content--left">
            <img className="main__content--img" src={Project} alt="" />
          </div>
          <div className="main__content--right">
            <h1 className="main__content--title">Work From Home</h1>
            <p className="main__content--para">
              Frontend developers can easily work from home, as their tasks are
              primarily digital. With a computer and a reliable internet
              connection, they can collaborate using tools like Git, Slack, and
              Zoom. This flexibility offers a better work-life balance and
              access to opportunities worldwide.
            </p>
          </div>
        </div>
        <hr className="hr3" />
        <div
          className="main__content"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <div className="main__content--left">
            <h1 className="main__content--title">Job Guarantee</h1>
            <p className="main__content--para">
            We're committed to your success — and if you don’t land your coding job by the end of the course, we’ll continue working with you until you do! While you’re job hunting, we’ll guide you through affiliate marketing strategies to help you start earning real commissions online.

Our job guarantee means that, whether you're building your coding career or generating income through affiliate marketing, you won’t be left behind. You’ll receive ongoing mentoring and support, and if it takes longer to land that coding job, you’ll have a sustainable way to earn while you search.
            </p>
          </div>
          <div className="main__content--right">
            <img className="main__content--img" src={Money} alt="" />
          </div>
        </div>
      </div>

      <div className="community" data-aos="fade-up" data-aos-duration="1000">
        <hr className="hr2" />
        <h1 className="community__title" data-aos="zoom-in">
          Student Testimonials
        </h1>
        <div className="community__wrapper">
          <div className="community__bubble" data-aos="fade-left">
            <h2 className="community__subtitle">Jack Kennedy - USA - $160K</h2>
            <p className="community__para">
              Good evening peeps! Just thought i'd let you know I landed a $160k
              job as a frontend developer today! Was scrolling on tiktok and
              found zakcodes and wasnt sure whether it was worth giving a try
              but here I am today landing my first full time role with an
              incredible income! I followed learnfrontend from start to finish
              and couldnt believe how simple zak made coding... learning html
              css and js was so easy whereas i tried before on youtube and got
              stuck in the notorious 'tutorial hell' anyway i hadnt even been in
              the discord which i shouldve been ages ago but zak and i had been
              communicating on whatsapp and the teamviewer teaching sessions
              were absolute gold, any issue i had he helped explain and solve.
              ultimately im stoked for the opportunity and glad to become one of
              the many testiomonials!
            </p>
          </div>
          <div
            className="community__bubble"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="community__subtitle">Dom - New Zealand - $95K</h2>
            <p className="community__para">
            Hey guys! I am only new to discord but I had to join the server to announce that I have landed my first frontend developer role here in the UK! I was told by my company not to disclose any salary figures but i can say it is extremely close to the six figure mark, which is insane to me. I have worked with Zak via Tik Tok, and can I say he has been a pleasure to be taught by, I was stuck for agesss learning javascript but he explained it in a way that made it so clear and concise. I finished LF in around 9 weeks (studied 3ish hrs per day, 4 days a week) and found it better than other courses I have done like freecodecamp, odin project, etc. I highly recommend LF to anyone looking for a career switch, a fresh start, or even a side hobby as in the process of learning I was able to find multiple clients in my area who needed websites! All in all I am stoked to have come across this course this year and am genuinely grateful for Zak and the team at LF!
            </p>
          </div>
          <div
            className="community__bubble"
            data-aos="fade-left"
            data-aos-duration="1000"
          >
            <h2 className="community__subtitle">Hussain - AUS - $120K</h2>
            <p className="community__para">
            $120,000 + Stock Option LANDED!
Hey everyone, thought id share my coding journey with you all! Basically I had vague interest in coding but was sort of all Over the place with my learning, fast forward about a month and I came across learn frontend on tik tok. 

I didn’t use the discord server (I probably should have lol) , I just completely followed the videos in the curriculum all the way through till the end. In 2 months I landed a 120k + stock which is still being determined exactly how much that will be! The job preparation section at the end was pivotal in landing my role, I think if you guys are currently applying, focus on the job prep section every day and just memorise all the theory. My interview was relatively easy I only had one coding question!

Anyway massive thanks to Zak, I can’t believe he made coding low key easy to understand and he really did only teach what you need to know to get job ready! I can’t wait to start working!!
            </p>
          </div>
          <div
            className="community__bubble"
            data-aos="fade-right"
            data-aos-duration="1000"
          >
            <h2 className="community__subtitle">Jennifer - USA - $110K</h2>
            <p className="community__para">
            I can’t believe how much my life has changed in just eight weeks! When I signed up for the coding course, I was looking for a new direction in my career, but I never imagined I’d land a six-figure job so quickly.

The course was intensive and hands-on, providing me with the practical skills I needed to succeed in the tech industry. Zak was incredibly supportive and always available to help with any questions I had. The curriculum was well-structured, covering everything from web development to data structures, and I loved the project-based approach that allowed me to build a portfolio I’m proud of.

Thanks to the networking opportunities and resume workshops offered, I felt confident applying for jobs. Just a month after completing the course, I received an offer from a leading tech company that exceeded my expectations!

I can’t recommend this course enough—it truly changed my life. If you’re considering a career in tech, don’t hesitate. Take the leap, and you might just find yourself in the position of your dreams, just like I did!
            </p>
          </div>
        </div>
      </div>
      <div className="premium__ad" data-aos="fade-in" data-aos-duration="1000">
        <p className="premium__subtitle">Begin your coding journey today!</p>
        <h1 className="premium__title">Become a Premium Member</h1>

        <a className="premium__btn--wrapper" href="/payment">
          <button className="premo__btn">Create Premium Account</button>
        </a>
      </div>
    </div>
  );
};

export default Home;
