import { addDoc, collection, onSnapshot } from "firebase/firestore";
import { app, auth, db } from "../firebase";
import getStripe from "./initializeStripe";

export async function createCheckoutSession(uid, for_premium, referralCode) {
  console.log('referralCode', referralCode);
  
  //const firestore = firebase.firestore();

  // monthly is first, lifetime second

  const price =
    for_premium === 1
      ? "price_1NInYTF9GMZUpcRmSH6SSaWS"
      : for_premium === 2
      ? "price_1OBqkXF9GMZUpcRmPVaxg7bc"
      : "";
  // Create a new checkout session in the subollection inside this users document
  console.log("uid", uid);
  const generalRef = collection(db, `users/${uid}/checkout_sessions`);

  const checkoutSessionRef = await addDoc(generalRef, {
    price,
    mode: for_premium === 2 ? "payment" : "subscription",
    success_url: window.location.origin,
    cancel_url: window.location.origin,
    metadata: {
      promotekit_referral: referralCode,
    }
  });

  /*const checkoutSessionRef = await db
    .collection("users")
    .doc(uid)
    .collection("checkout_sessions")
    .add({
      // replace the price_XXX value with the correct value from your product in stripe.
      price: "price_1MVSVyF9GMZUpcRmIMZ0uxZm",
      success_url: window.location.origin,
      cancel_url: window.location.origin,
    });*/

  // Wait for the CheckoutSession to get attached by the extension
  // checkoutSessionRef.onSnapshot(async (snap) => {
  //   console.log('onSnapshot');
  //   const { sessionId } = snap.data();
  //   if (sessionId) {
  //     // We have a session, let's redirect to Checkout
  //     // Init Stripe
  //     const stripe = await getStripe();
  //     stripe.redirectToCheckout({ sessionId });
  //   }
  // });

  //const q = query(collection(db, "cities"), where("state", "==", "CA"));
  const unsubscribe = onSnapshot(checkoutSessionRef, async (snap) => {
    const { sessionId } = snap.data();
    // console.log("snap", snap);
    // console.log("sessionId", sessionId);
    if (sessionId) {
      console.log("sessionId received");
      // We have a session, let's redirect to Checkout
      // Init Stripe
      const stripe = await getStripe();
      stripe.redirectToCheckout({ sessionId });
    }
  });

  console.log(unsubscribe);
}
